html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: "Inter", ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Inter", ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.app {
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  margin: 40px;
  background: none;
}

.profile {
  padding: 20px;
}

.profile h1 {
  font-size: 36px;
  line-height: 40px;
  margin: 10px 0;
}

.profile p {
  font-size: 16px;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.featured-roles {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.featured-roles .role {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
  font-weight: 600;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.social-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 24px;
}

.template-selector {
  position: absolute;
  top: 10px;
  right: 10px;
}

.template-selector select {
  padding: 10px;
  color: #666;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: none;
  text-align: center;
  cursor: pointer;
}

.template-selector select:hover {
  background-color: #f0f0f0;
}

.button-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.button-link {
  text-align: center;
  min-width: 350px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 40px 0;
}

/* Mobile Design */
@media (max-width: 600px) {
  .app {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .social-links {
    margin-bottom: 20px;
  }

  .social-links a {
    font-size: 20px;
  }
}

/* Desktop Design */
@media (min-width: 601px) {
  .app {
    background: #ffffff;
    width: 80%;
  }

  .profile {
    display: flex;
    align-items: flex-start;
    text-align: left;
    flex-direction: column;
  }

  .profile-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .featured-roles {
    justify-content: left;
  }

  .button-links {
    align-items: flex-start;
    margin-top: 20px;
  }

  .profile-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.bio-container.expanded .bio-content {
  display: block;
  max-height: none;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
